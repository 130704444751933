@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

* {
  font-family: "Montserrat", sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.textShadow-blue {
  text-shadow: 2px 2px #0e3872;
}

.textShadow-red {
  text-shadow: 2px 2px #da1212;
}

.shadow-text-blue {
  @apply textShadow-blue;
}

.shadow-text-red {
  @apply textShadow-red;
}
